import { Store } from 'json-api-models';
import ProfileUser from '@/Entity/ProfileUser'

export default {
    install: (app, options) => {
        const models = new Store({
            'users': ProfileUser
        })
        app.config.globalProperties.$models = models
    }
}