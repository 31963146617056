import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import models from './store/Models'


require("./styles/app.scss")
require("./styles/main.scss")
createApp(App)
.use(router)
.use(models)
.mount('#app')
